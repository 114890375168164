var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"commonDialog":_vm.cDialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-layout',[_c('v-flex',[_vm._v(" Manage Category ")]),_c('v-flex',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mx-4 white--text",attrs:{"depressed":"","small":"","color":"cyan"},on:{"click":_vm.createEnquiryCategory}},[_c('span',{staticClass:"font-size-16 font-weight-600"},[_vm._v("Add Category")])])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-form',{ref:"ticketCollecttionForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateEnquiryCategory.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('table',{staticClass:"width-100"},[_c('tbody',_vm._l((_vm.categoryList),function(type,index){return _c('tr',{key:index},[_c('td',[_c('v-text-field',{staticClass:"px-4 py-2",attrs:{"dense":"","filled":"","placeholder":`Category`,"solo":"","flat":"","hide-details":"","disabled":_vm.pageLoading ||
                          type.equipment_count > 0 ||
                          type.product_count > 0,"loading":_vm.pageLoading,"rules":[
                          _vm.validateRules.required(type.text, 'Category Name'),
                        ],"color":"cyan"},model:{value:(type.text),callback:function ($$v) {_vm.$set(type, "text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"type.text"}}),(
                          type.equipment_count > 0 || type.product_count > 0
                        )?_c('span',{staticClass:"px-4 py-2 red--text font-weight-600"},[_vm._v("This Category already used in other transactions")]):_vm._e()],1),(_vm.categoryList.length > 1)?_c('td',{attrs:{"width":"50"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"deep-orange","disabled":type.equipment_count > 0 ||
                              type.product_count > 0},on:{"click":function($event){return _vm.deleteProductCategory(index)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Click here to delete")])])],1):_vm._e()])}),0)])])],1)],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"depressed":"","disabled":_vm.pageLoading || !_vm.formValid,"loading":_vm.pageLoading,"color":"cyan"},on:{"click":_vm.updateEnquiryCategory}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"depressed":"","disabled":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close-dialog', true)}}},[_vm._v(" Close ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }